<template>
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="handleSubmit(onSubmit)" ref="form" v-loading="loader">
            <div class="mt-4 cardStyle p-3">
                <div class="baritastic-program-form mt-1">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <base-input name="program_name" label="Program Name*" v-model="form.first_name" :rules="{ custom_required: true }"
                                placeholder="Program Name"></base-input>
                        </div>
                        <div class="col-12 col-md-6">
                            <base-input name="owner_name" label="Owner Name*" v-model="form.owner_name"
                                :rules="{ custom_required: true }" placeholder="Owner Name"></base-input>
                        </div>
                        <div class="col-12 col-md-6">
                            <base-input name="email" label="Email*" v-model="form.email" :rules="{ custom_required: true, email: true }"
                                placeholder="Email"></base-input>
                        </div>
                        <div class="col-12 col-md-6">
                            <base-select name="tier" label="Tier*" v-model="form.tier" :options="tierOptions"
                                :rules="{ custom_required: true }"></base-select>
                        </div>
                        <div class="col-12 col-md-6">
                            <base-select name="timezone" label="Timezone*" v-model="form.timezone" :options="timezoneOptions"
                                :rules="{ custom_required: true }"></base-select>
                        </div>
                        <div class="col-12 col-md-6">
                            <base-input name="programmer_code" label="Programmer Code*" v-model="form.programmer_code"
                                :rules="{ custom_required: true }" placeholder="Programmer Code"></base-input>
                        </div>
                        <div class="col-12 col-md-6" v-if="formType == 'update'">
                            <base-input name="third_checklist_name" label="3rd Checklist Name*" v-model="form.third_checklist_name"
                                :rules="{ custom_required: true }" placeholder="3rd Checklist Name" maxlength="20"></base-input>
                        </div>
                        <div class="col-12 col-md-6" v-if="formType == 'store'">
                            <base-input name="username" label="Username*" v-model="form.username"
                                :rules="{ custom_required: true }" placeholder="Username" autocomplete="new-username"></base-input>
                        </div>
                        <div class="col-12 col-md-6" v-if="formType == 'store'">
                            <base-input type="password" name="password" label="Password*" v-model="form.password"
                                :rules="{ custom_required: true }" placeholder="Password" autocomplete="new-password"></base-input>
                        </div>
                        <div class="col-12 col-md-6" v-if="formType == 'store'">
                            <base-input type="password" name="password_confirmation" label="Confirm Password*" v-model="form.password_confirmation"
                                :rules="{ custom_required: true, custom_confirmed:'password' }" placeholder="Confirm Password" autocomplete="confirm-password"></base-input>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="" class="form-control-label">Email confirmation</label>
                                <div class="row">
                                    <div class="col-6">
                                        <el-checkbox label="Yes" border
                                            :value="form.email_check == 1"
                                            class="checkbox-custom checkbox-custom-bari w-100"
                                            @change="(value) => form.email_check = 1">YES</el-checkbox>
                                    </div>
                                    <div class="col-6">
                                        <el-checkbox label="0" border
                                            :value="form.email_check == 0"
                                            class="checkbox-custom checkbox-custom-bari w-100"
                                            @change="(value) => form.email_check = 0">NO</el-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="" class="form-control-label">Join Program Popup</label>
                                        <div class="row">
                                            <div class="col-6">
                                                <el-checkbox label="yes" border
                                                    :value="form.popup_check == 1"
                                                    class="checkbox-custom checkbox-custom-bari w-100"
                                                    @change="(value) => form.popup_check = 1">YES</el-checkbox>
                                            </div>
                                            <div class="col-6">
                                                <el-checkbox label="no" border
                                                     :value="form.popup_check == 0"
                                                    class="checkbox-custom checkbox-custom-bari w-100"
                                                    @change="(value) => form.popup_check = 0">NO</el-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12" v-if="form.popup_check">
                                    <validation-provider :rules="{ custom_required: true }"
                                        name="join_program_popup_message" v-slot="{ errors, valid, invalid, validated }">
                                        <div class="form-group">
                                            <label class="form-control-label">Join Program Popup Message*</label>
                                            <textarea v-model="form.popup_msg" class="form-control"
                                                rows="5" :class="[{ 'is-invalid': invalid && validated }]"></textarea>
                                            <div v-if="errors[0]" class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </validation-provider>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                            <label for="" class="form-control-label">Dashboard Program Popup</label>
                                            <div class="row">
                                                <div class="col-6">
                                                    <el-checkbox label="yes" border
                                                        :value="form.popup_check1 == 1"
                                                        class="checkbox-custom checkbox-custom-bari w-100"
                                                        @change="(value) => form.popup_check1 = 1">YES</el-checkbox>
                                                </div>
                                                <div class="col-6">
                                                    <el-checkbox label="no" border
                                                        :value="form.popup_check1 == 0"
                                                        class="checkbox-custom checkbox-custom-bari w-100"
                                                        @change="(value) => form.popup_check1 = 0">NO</el-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div class="col-12" v-if="form.popup_check1">
                                    <validation-provider :rules="{ custom_required: true }"
                                        name="dashboard_program_popup_message"
                                        v-slot="{ errors, valid, invalid, validated }">
                                        <div class="form-group">
                                            <label class="form-control-label">Dashboard Program Popup Message*</label>
                                            <textarea v-model="form.popup_msg1" class="form-control"
                                                rows="5" :class="[{ 'is-invalid': invalid && validated }]"></textarea>
                                            <div v-if="errors[0]" class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </validation-provider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-4">
                <base-button native-type="submit" type="dark-blue" class="px-5" size="xl" :disabled="loader">{{ submitButton }}</base-button>
            </div>
        </form>
    </validation-observer>
</template>
  
<script>
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
import { constants } from '@/util/constants';
export default {
    props: {
        baritasticProgram: Object,
        loader: Boolean,
        formType: String
    },
    components: {
    },
    mixins: [generateErrorMessageMixin],
    data() {
        return {
            form: {
                ...this.$store.getters['BaritasticProgramModule/getBaritasticProgram']
            },
            tierOptions: constants.baritasticProgram.tierOptions,
            timezoneOptions: constants.baritasticProgram.timezoneOptions
        };
    },
    mounted() {
        if (this.baritasticProgram) {
            this.setValues(this.baritasticProgram);
        }
    },
    watch: {
        'baritasticProgram': function (newValue) {
            this.setValues(newValue);
        }
    },
    methods: {
        setValues(value) {
            this.form.first_name = value?.first_name || '';
            this.form.owner_name = value?.owner_name || '';
            this.form.email = value?.email || '';
            this.form.tier = value?.tier || '';
            this.form.timezone = value?.timezone || '';
            this.form.programmer_code = value?.programmer_code || '';
            this.form.third_checklist_name = value?.third_checklist_name || '';
            this.form.username = value?.username || '';
            this.form.email_check = value?.email_check || 0;
            this.form.popup_check = value?.popup_check || 0;
            this.form.popup_check1 = value?.popup_check1 || 0;
            this.form.popup_msg = value?.popup_msg || '';
            this.form.popup_msg1 = value?.popup_msg1 || '';
        },
        onSubmit() {
            let fd = {...this.form};
            if (this.formType == 'update') this.$emit('update', { fd, id: this.baritasticProgram.id });
            else this.$emit('add', fd);
        }

    },
    computed: {
        submitButton() {
            return this.formType == 'store' ? 'Add' : 'Update';
        }
    }
};
</script>
  
<style lang="scss">
.baritastic-program-form {
    .form-group {
        margin-bottom: 0.65rem !important;
    }

    input {
        &.form-control {
            height: calc(1.5em + 1.25rem + 2px);
        }
    }
}
</style>
  